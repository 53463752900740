import { NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import {
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatToolbarModule,
  MatTooltipModule,
  MatMenuModule,
  MatSnackBarModule,
  MatChipsModule,
  MatSelectModule,
  MatRadioModule,
  MatTabsModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatCheckboxModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatStepperModule,
  MatExpansionModule,
  MatGridListModule,
  MatRippleModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

/* Breakpoints based in bootstrap */

const CUSTOM_BREAKPOINTS = [
  { alias: 'xs', mediaQuery: 'only screen and (min-width: 0px)' },
  { alias: 'sm', mediaQuery: 'only screen and (min-width: 576px)' },
  { alias: 'md', mediaQuery: 'only screen and (min-width: 768px)' },
  { alias: 'lg', mediaQuery: 'only screen and (min-width: 992px)' },
  { alias: 'xl', mediaQuery: 'only screen and (min-width: 1200px)' }
];

@NgModule({
  declarations: [],
  imports: [
    MatRippleModule,
    MatGridListModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatCardModule,
    MatInputModule,
    MatListModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatChipsModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    LayoutModule,
    MatStepperModule,
    FlexLayoutModule.withConfig({ disableDefaultBps: true }, CUSTOM_BREAKPOINTS)
  ],
  exports: [
    MatRippleModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    MatExpansionModule,
    MatInputModule,
    MatListModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatChipsModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatStepperModule,
    FlexLayoutModule
  ]
})
export class MaterialModule {}
