import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../types/classes/user.class';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly url = 'user';
  users$: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  get() {
    if (this.users$.value) {
      return this.users$.asObservable();
    }

    return this.http
      .get(this.url)
      .pipe(tap((user: User) => this.users$.next(user)));
  }

  get isAdmin() {
    return this.users$.value && this.users$.value.isStaff;
  }
}
