import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-header',
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-title">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon *ngIf="titleIcon" svgIcon="{{ titleIcon }}"></mat-icon>
        <div class="mat-title no-margin">
          {{ title | translate | titlecase }}
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() titleIcon: string;
  @Input() canAdd = true;

  @Output() add = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
