import { Component, Input, OnInit } from '@angular/core';
import { SidenavOption } from '../../types/interfaces/sidenav-option.interface';
import { User } from '../../types/classes/user.class';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  template: `
    <div
      fxLayout="column"
      class="sidenav full-height"
      [ngClass]="{ 'sidenav--collapsed': collapsed }"
    >
      <div fxLayout="column" class="full-height">
        <mat-list>
          <div *ngFor="let option of sidenavOptions; let first = first">
            <mat-list-item
              [disableRipple]="true"
              class=" sidenav__item--height"
              (click)="onOption(option.routerLink)"
              [ngClass]="{
                'sidenav__item--active-route': active === option.routerLink,
                'sidenav__item--border': first
              }"
              matTooltip="{{ option.name | titlecase }}"
              matTooltipShowDelay="1000"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                class="sidenav__item--padding sidenav__item--no-select"
                [ngClass]="{
                  'sidenav__item--select': active === option.routerLink
                }"
              >
                <mat-icon mat-list-icon [svgIcon]="option.icon"></mat-icon>
                <span [ngClass]="{ 'sidenav--collapsed': collapsed }">
                  {{ option.name | translate | titlecase }}
                </span>
              </div>
            </mat-list-item>
          </div>
        </mat-list>
      </div>

      <div fxLayout="column">
        <mat-nav-list>
          <mat-divider></mat-divider>
          <mat-list-item
            class=" sidenav__close"
            (click)="collapsed = !collapsed"
          >
            <div fxLayout="row" fxFlex fxLayoutAlign="end">
              <mat-icon [svgIcon]="collapsedIcon"></mat-icon>
            </div>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </div>
  `,
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  collapsed: boolean;

  @Input() user: User;
  @Input() sidenavOptions: SidenavOption[];
  @Input() active: string;

  constructor(private router: Router) {}

  ngOnInit() {}

  get collapsedIcon() {
    return this.collapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left';
  }

  onOption(link: string) {
    this.router.navigate([link]);
  }
}
