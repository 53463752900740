import { Injectable } from '@angular/core';
import { UserService } from '../../core/services';
import { tap } from 'rxjs/operators';
import { Token } from '../types/interfaces/token.interface';
import { HttpClient } from '@angular/common/http';
import { LoginCredentials } from '../types/classes/login-credentials.class';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public readonly ACCESS_TOKEN_KEY = 'aid_admin_access_token';
  public readonly REFRESH_TOKEN_KEY = 'aid_admin_refresh_token';

  constructor(private userService: UserService, private http: HttpClient) {}

  get accessToken() {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  get hasTokens() {
    return (
      !!localStorage.getItem(this.ACCESS_TOKEN_KEY) &&
      !!localStorage.getItem(this.REFRESH_TOKEN_KEY)
    );
  }

  login(loginCredentials: LoginCredentials) {
    return this.http.post('login', loginCredentials).pipe(
      tap((token: Token) => {
        this.saveCredentials(token);
      })
    );
  }

  logOut() {
    this.clearCredentials();
    this.userService.users$.next(null);
  }

  saveCredentials(token: Token) {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, token.access);
    localStorage.setItem(this.REFRESH_TOKEN_KEY, token.refresh);
  }

  clearCredentials() {
    localStorage.removeItem(this.ACCESS_TOKEN_KEY);
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
  }

  refreshToken() {
    const refreshToken = localStorage.getItem(this.REFRESH_TOKEN_KEY);

    return this.http
      .post('refresh-token', {
        refresh: refreshToken
      })
      .pipe(
        tap((token: Token) => {
          localStorage.setItem(this.ACCESS_TOKEN_KEY, token.access);
        })
      );
  }
}
