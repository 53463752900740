import {
  Component,
  ContentChild,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { MatMenu } from '@angular/material';

@Component({
  selector: 'app-add-button',
  template: `
    <div>
      <a *ngIf="menu" class="menu" [matMenuTriggerFor]="menu">
        <button mat-fab class="add-button" (click)="onClick($event)">
          <mat-icon class="icon" svgIcon="add"></mat-icon>
        </button>
      </a>
      <a *ngIf="!menu">
        <button mat-fab class="add-button" (click)="onClick($event)">
          <mat-icon class="icon" svgIcon="add"></mat-icon>
        </button>
      </a>
    </div>
  `,
  styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent implements OnInit {
  @Output()
  add: EventEmitter<void> = new EventEmitter<void>();
  @ContentChild('menu')
  menu: MatMenu;

  constructor() {}

  ngOnInit() {}

  onClick(event) {
    this.add.emit();
  }
}
