import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconRegistryService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  addSvgIcons() {
    const folder = 'assets/icons';
    const iconDefinitions = {
      first_name: `${folder}/first-name.svg`,
      last_name: `${folder}/last-name.svg`,
      contacts: `${folder}/sharp-contacts-24px.svg`,
      supervised_user_circle: `${folder}/sharp-supervised_user_circle-24px.svg`,
      whatshot: `${folder}/sharp-whatshot-24px.svg`,
      dashboard: `${folder}/sharp-dashboard-24px.svg`,
      message: `${folder}/sharp-message-24px.svg`,
      perm_device_information: `${folder}/sharp-perm_device_information-24px.svg`,
      email: `${folder}/sharp-email-24px.svg`,
      lock: `${folder}/sharp-lock-24px.svg`,
      sms: `${folder}/sharp-sms-24px.svg`,
      flag: `${folder}/sharp-flag-24px.svg`,
      person: `${folder}/sharp-person-24px.svg`,
      german_flag: `${folder}/german_flag.svg`,
      english_flag: `${folder}/english_flag.svg`,
      event_note: `${folder}/sharp-event_note-24px.svg`,
      exit_to_app: `${folder}/sharp-exit_to_app-24px.svg`,
      keyboard_arrow_left: `${folder}/sharp-keyboard_arrow_left-24px.svg`,
      keyboard_arrow_right: `${folder}/sharp-keyboard_arrow_right-24px.svg`,
      add: `${folder}/sharp-add-24px.svg`,
      edit: `${folder}/sharp-edit-24px.svg`,
      more_vert: `${folder}/sharp-more_vert-24px.svg`,
      delete: `${folder}/sharp-delete-24px.svg`,
      done: `${folder}/sharp-done-24px.svg`,
      not_interested: `${folder}/sharp-not_interested-24px.svg`
    };
    Object.keys(iconDefinitions).forEach(key => {
      this.iconRegistry.addSvgIcon(
        key,
        this.sanitizer.bypassSecurityTrustResourceUrl(iconDefinitions[key])
      );
    });
  }
}
