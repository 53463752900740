/**
 * A table column object is a key value object where the key represents the name of the field
 * that is displayed by default
 * title - the title of the column
 * isArray - if the value to display is an array
 * displayFn - Takes as param a row and return what should be displayed from that row. If the
 * isArray field is set to true, the param is a value from the array, not the entire row.
 */
export class TableColumns {
  [name: string]: {
    title: string;
    isArray?: boolean;
    displayFn?: (value: any) => string;
    translate?: boolean;
    actions?: {
      icon: string;
      text: string;
      press: (value: any) => void;
    }[];
  };
}
