import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <div class="confirm-dialog" fxLayout="column" fxLayoutAlign="space-between">
      <div class="mat-title">
        {{ data.title | titlecase }}
      </div>
      <div class="mat-subheading-2 confirm-dialog-subtitle">
        {{ data.message }}
      </div>
      <div fxLayout="row" fxLayoutAlign="flex-end center ">
        <button mat-button (click)="onCancel()">
          {{ 'no' | uppercase }}
        </button>
        <button mat-button color="primary" (click)="onConfirm()">
          {{ 'yes' | uppercase }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
    }
  ) {}

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(true);
  }
}
