import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/services';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private readonly baseUrl = environment.api;

  constructor(
    private authService: AuthService,
    private jwtHelper: JwtHelperService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let apiReq = request;

    if (
      !/^https?:\/\/(.*)/.test(request.url) &&
      !/^assets\/(.*)/.test(request.url)
    ) {
      apiReq = request.clone({ url: `${this.baseUrl}/${request.url}` });
    }

    const refreshToken: string = localStorage.getItem(
      this.authService.REFRESH_TOKEN_KEY
    );

    apiReq = this.setAccessToken(apiReq);

    if (
      this.jwtHelper.isTokenExpired() &&
      !/^(.*)refresh-token/.test(request.url) &&
      refreshToken
    ) {
      return this.handleRefreshToken(apiReq, next);
    } else {
      return next.handle(apiReq);
    }
  }

  private setAccessToken(apiReq: HttpRequest<any>) {
    const accessToken: string = localStorage.getItem(
      this.authService.ACCESS_TOKEN_KEY
    );

    if (accessToken) {
      apiReq = apiReq.clone({
        headers: apiReq.headers.set('Authorization', `Bearer ${accessToken}`)
      });
    }

    return apiReq;
  }

  private handleRefreshToken(apiReq: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      switchMap(() => {
        apiReq = this.setAccessToken(apiReq);
        return next.handle(apiReq);
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authService.logOut();
          this.router.navigate(['/auth'], {
            queryParams: {
              redirectTo: document.location.pathname
            }
          });
        }

        return EMPTY;
      })
    );
  }
}
