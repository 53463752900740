import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as fromCoreContainer from './core/containers';
import * as coreGuards from './core/guards';
import * as fromGuards from './auth/guards';

const routes: Routes = [
  {
    path: '',
    canActivate: [coreGuards.AuthGuard],
    component: fromCoreContainer.MainLayoutComponent,
    children: [
      {
        path: '',
        component: fromCoreContainer.UnderConstructionComponent
      },
      {
        path: 'verifications',
        loadChildren: './verifications/verifications.module#VerificationsModule'
      },
      {
        path: 'organizations',
        loadChildren: './organizations/organizations.module#OrganizationsModule'
      },
      {
        path: 'users',
        loadChildren: './users/users.module#UsersModule'
      }
    ]
  },
  {
    path: 'auth',
    canActivate: [fromGuards.NotLoggedInGuard],
    loadChildren: './auth/auth.module#AuthModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
