import { Component } from '@angular/core';
import { IconRegistryService } from './core/services/icon-registry.service';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material';
import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
import localeDe from '@angular/common/locales/de';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private iconRegistry: IconRegistryService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>
  ) {
    this.iconRegistry.addSvgIcons();
    this.setLanguage();
  }

  private setLanguage() {
    this.translate.addLangs(['en', 'de']);
    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    const localStorageLanguage = localStorage.getItem(
      environment.localStorage.language
    );

    if (localStorageLanguage) {
      this.translate.use(localStorageLanguage);
      return;
    }

    const language = browserLang.match(/en|de/) ? browserLang : 'en';

    this.translate.use(language);
    this.dateAdapter.setLocale(language);

    localStorage.setItem(environment.localStorage.language, language);
  }
}
