import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services';
import { AuthService } from '../../auth/services/auth.service';
import { User } from '../types/classes/user.class';
import { catchError, first, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.hasTokens) {
      const url = state.url.split('?')[0];
      this.router.navigate(['/auth'], {
        queryParams: {
          url,
          queryParams: JSON.stringify(next.queryParams)
        }
      });
      return false;
    }

    return this.userService.get().pipe(
      first(),
      map((user: User) => {
        if (!user.isStaff) {
          throw Error('User do not have permission');
        }
        return true;
      }),
      catchError(error => {
        this.snackBar.open('We did not found any admin account', 'OK', {
          duration: 5000
        });
        this.authService.logOut();
        this.router.navigate(['auth']);
        return of(false);
      })
    );
  }
}
