import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class NotLoggedInGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const accessToken = localStorage.getItem(this.authService.ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(
      this.authService.REFRESH_TOKEN_KEY
    );

    if (!(accessToken && refreshToken)) {
      return true;
    }

    this.router.navigate(['']);
  }
}
