import { Injectable } from '@angular/core';
import { SidenavOption } from '../types/interfaces/sidenav-option.interface';

@Injectable()
export class SidenavService {
  constructor() {}

  public buildSidenavOptions(): SidenavOption[] {
    const options: SidenavOption[] = [
      {
        name: 'dashboard',
        icon: 'dashboard',
        routerLink: '/'
      },
      {
        name: 'verifications.name',
        icon: 'supervised_user_circle',
        routerLink: 'verifications'
      },
      {
        name: 'organizations.name',
        icon: 'supervised_user_circle',
        routerLink: 'organizations'
      },
      {
        name: 'users.name',
        icon: 'supervised_user_circle',
        routerLink: 'users'
      }
    ];

    return options;
  }
}
