import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-under-construction',
  template: `
    <div fxLayout="row" fxLayoutAlign="center start">
      <div class="mat-title">
        {{ 'under-construction' | translate }}
      </div>
    </div>
  `,
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
