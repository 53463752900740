import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { PageEvent } from '@angular/material';
import { ResourceType } from '../../types/enums/resource-type.enum';
import { TableColumns } from '../../types/classes/table-columns.class';
import { TdDialogService } from '@covalent/core';
import { Verification } from '../../../verifications/types/interfaces/verifications.interface';

@Component({
  selector: 'app-http-table',
  template: `
    <div class="container full-height" fxLayout="column" fxFlexFill>
      <div class="loading-shade" *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>

      <app-table-header
        [title]="title"
        [titleIcon]="titleIcon"
        [canAdd]="canAdd"
        (add)="add.emit()"
      ></app-table-header>
      <mat-form-field *ngIf="enableFiltering" class="full-width">
        <mat-label>{{ filterLabel | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)" />
      </mat-form-field>

      <div
        [ngStyle]="{
          'max-height': tableHeight ? tableHeight + 'px' : '100%'
        }"
        style="overflow: auto; position: relative"
        fxLayout="row"
      >
        <table mat-table [dataSource]="data" matSort class="full-width">
          <ng-container
            *ngFor="let column of tableColumns | keyvalue"
            matColumnDef="{{ column.key }}"
          >
            <ng-container *ngIf="column.key === 'actions'">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="action-column">
                <button
                  *ngIf="
                    canEdit ||
                    canRemove ||
                    (column.value &&
                      column.value.actions &&
                      column.value.actions.length > 0)
                  "
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  (click)="$event.stopPropagation()"
                >
                  <mat-icon svgIcon="more_vert"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    *ngIf="canEdit"
                    mat-menu-item
                    (click)="edit.emit(element)"
                  >
                    <mat-icon matPrefix svgIcon="edit"></mat-icon>
                    {{ 'edit' | translate | titlecase }}
                  </button>
                  <button
                    *ngIf="canAdd"
                    mat-menu-item
                    (click)="remove.emit(element)"
                  >
                    <mat-icon
                      matPrefix
                      svgIcon="delete"
                      color="warn"
                    ></mat-icon>
                    {{ 'remove' | translate | titlecase }}
                  </button>

                  <ng-container *ngIf="column.value">
                    <button
                      *ngFor="let action of column.value.actions"
                      mat-menu-item
                      (click)="action.press(element)"
                    >
                      <mat-icon matPrefix [svgIcon]="action.icon"></mat-icon>
                      {{ action.text }}
                    </button>
                  </ng-container>
                </mat-menu>

                <ng-container *ngIf="element.status === 'pending'">
                  <button
                    mat-icon-button
                    *ngIf="canAccept"
                    (click)="onAccept(element)"
                  >
                    <mat-icon svgIcon="done"></mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    *ngIf="canDecline"
                    (click)="onDeny(element)"
                  >
                    <mat-icon svgIcon="not_interested"></mat-icon>
                  </button>
                </ng-container>

                <mat-icon
                  *ngIf="element.status === 'accepted'"
                  svgIcon="done"
                  color="primary"
                ></mat-icon>
                <mat-icon
                  *ngIf="element.status === 'denied'"
                  svgIcon="not_interested"
                  color="warn"
                ></mat-icon>
              </td>
            </ng-container>
            <ng-container *ngIf="column.key !== 'actions'">
              <th mat-header-cell *matHeaderCellDef>
                {{ column.value.title | translate | titlecase }}
              </th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="!column.value.isArray; else isArray">
                  <div *ngIf="column.value.displayFn; else noProperty">
                    {{ column.value.displayFn(element) }}
                  </div>
                  <ng-template #noProperty>
                    {{
                      element[column.key] ? element[column.key] : '-'
                    }}</ng-template
                  >
                </div>
                <ng-template #isArray>
                  <div *ngFor="let item of element[column.key]">
                    <div *ngIf="column.value.displayFn; else noPropertyArray">
                      {{ item ? column.value.displayFn(item) : '-' }}
                    </div>
                    <ng-template #noPropertyArray>
                      {{ item ? item : '-' }}</ng-template
                    >
                  </div>
                  <div *ngIf="element[column.key].length === 0">-</div>
                </ng-template>
              </td>
            </ng-container>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            [ngClass]="{ selectable: selectable }"
            (click)="onSelect(row)"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </div>
      <div *ngIf="(!data || data.length === 0) && !loading">
        <h4 class="text-center">
          {{ 'there-are-no' | translate }} {{ title | lowercase }}
        </h4>
      </div>
      <div [hidden]="!enablePagination">
        <mat-paginator
          [pageSizeOptions]="[10, 20, 30]"
          [length]="count"
          (page)="page.emit($event)"
        ></mat-paginator>
      </div>
    </div>
  `,
  styleUrls: ['./http-table.component.scss']
})
export class HttpTableComponent implements OnInit {
  @Input() data: any[];
  @Input() title: string;
  @Input() titleIcon: string;
  @Input() loading: boolean;
  @Input() enablePagination = true;
  @Input() enableFiltering = false;
  @Input() tableColumns: TableColumns;
  @Input() canAdd = true;
  @Input() tableHeight: number;
  @Input() canEdit = false;
  @Input() canRemove = false;
  @Input() canAccept = false;
  @Input() canDecline = false;
  @Input() selectable = false;
  @Input() count: number;
  @Input() filterLabel = 'Filter';
  @Input() resourceType: ResourceType;

  @Output() edit = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() select = new EventEmitter();
  @Output() accept = new EventEmitter();
  @Output() deny = new EventEmitter();
  @Output() page = new EventEmitter<PageEvent>();
  @Output() filter = new EventEmitter<string>();

  displayedColumns: string[];
  timer = 0;

  constructor(private _dialogService: TdDialogService) {}

  ngOnInit() {
    this.displayedColumns = Object.keys(this.tableColumns);
  }

  applyFilter(filterValue: string) {
    this.timer = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - this.timer > 500) {
        this.filter.emit(filterValue);
      }
    }, 500);
  }

  onSelect(row) {
    if (this.selectable) {
      this.select.emit(row);
    }
  }

  onAccept(element: Verification): void {
    this._dialogService
      .openConfirm({
        message: `Are you sure do you want to accept ${element.companyName}?`,
        title: 'Accept',
        cancelButton: 'Cancel',
        acceptButton: 'Yes'
      })
      .afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this.accept.emit(element);
        }
      });
  }

  onDeny(element: Verification): void {
    this._dialogService
      .openConfirm({
        message: `Are you sure do you want to deny ${
          element.companyName
        }'s application?`,
        title: 'Deny',
        cancelButton: 'Cancel',
        acceptButton: 'Yes'
      })
      .afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this.deny.emit(element);
        }
      });
  }
}
