import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { TableColumns } from '../../types/classes/table-columns.class';
import { ResourceType } from '../../types/enums/resource-type.enum';

@Component({
  selector: 'app-data-table',
  template: `
    <div fxLayout="column" style="max-height: 100%">
      <div class="container full-height" fxLayout="column" fxFlexFill>
        <app-table-header
          [title]="title"
          [titleIcon]="titleIcon"
          [canAdd]="canAdd"
          (add)="add.emit()"
        ></app-table-header>
        <mat-form-field
          *ngIf="enableFiltering && dataSource && dataSource.data.length !== 0"
          class="full-width"
        >
          <mat-label>{{ filterLabel }}</mat-label>
          <input matInput (keyup)="applyFilter($event.target.value)" />
        </mat-form-field>

        <div
          [ngStyle]="{
            'max-height': tableHeight ? tableHeight + 'px' : '100%'
          }"
          style="overflow: auto; position: relative"
          fxLayout="row"
        >
          <table mat-table [dataSource]="dataSource" matSort class="full-width">
            <ng-container
              *ngFor="let column of tableColumns | keyvalue"
              matColumnDef="{{ column.key }}"
            >
              <ng-container *ngIf="column.key === 'actions'">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="action-column">
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-icon svgIcon="more_vert"></mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      *ngIf="canEdit"
                      mat-menu-item
                      (click)="edit.emit(element)"
                    >
                      <mat-icon matPrefix svgIcon="edit"></mat-icon>
                      {{ 'edit' | translate | titlecase }}
                    </button>
                    <button
                      *ngIf="canAdd"
                      mat-menu-item
                      (click)="remove.emit(element)"
                    >
                      <mat-icon
                        matPrefix
                        svgIcon="delete"
                        color="warn"
                      ></mat-icon>
                      {{ 'remove' | translate | titlecase }}
                    </button>
                  </mat-menu>
                </td>
              </ng-container>
              <ng-container *ngIf="column.key !== 'actions'">
                <th mat-header-cell *matHeaderCellDef>
                  {{ column.value.title | translate | titlecase }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="!column.value.isArray; else isArray">
                    <div *ngIf="column.value.displayFn; else noProperty">
                      {{
                        column.value.translate
                          ? column.value.displayFn(element)
                          : column.value.displayFn(element)
                      }}
                    </div>
                    <ng-template #noProperty>
                      {{
                        element[column.key]
                          ? column.value.translate
                            ? element[column.key]
                            : element[column.key]
                          : '-'
                      }}</ng-template
                    >
                  </div>
                  <ng-template #isArray>
                    <div *ngFor="let item of element[column.key]">
                      <div *ngIf="column.value.displayFn; else noPropertyArray">
                        {{ item ? column.value.displayFn(item) : '-' }}
                      </div>
                      <ng-template #noPropertyArray>
                        {{ item ? item : '-' }}</ng-template
                      >
                    </div>
                    <div *ngIf="element[column.key].length === 0">-</div>
                  </ng-template>
                </td>
              </ng-container>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              [ngClass]="{ selectable: selectable }"
              (click)="onSelect(row)"
              mat-row
              *matRowDef="let row; columns: displayedColumns"
            ></tr>
          </table>
        </div>
        <div
          [hidden]="
            !(
              dataSource &&
              enablePagination &&
              !loading &&
              dataSource.filteredData.length !== 0
            )
          "
        >
          <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
        </div>
        <div
          *ngIf="
            (!dataSource ||
              dataSource.filteredData.length === 0 ||
              dataSource.data.length === 0) &&
            !loading
          "
        >
          <h4 class="text-center">
            {{ 'there-are-no' | translate }} {{ title | lowercase }}
          </h4>
        </div>
        <div *ngIf="loading">
          <mat-spinner color="primary" style="margin: auto"></mat-spinner>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableComponent implements OnInit {
  @Input() title: string;
  @Input() titleIcon: string;
  @Input() loading: boolean;
  @Input() enablePagination = true;
  @Input() enableFiltering = false;
  @Input() tableColumns: TableColumns;
  @Input() canAdd = true;
  @Input() tableHeight: number;
  @Input() canEdit = true;
  @Input() canRemove = true;
  @Input() canComment: boolean;
  @Input() resourceType: ResourceType;
  @Input() selectable = false;
  @Input() filterLabel = 'Filter';

  @Input() set data(data: any[]) {
    if (data) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      if (this.enablePagination) {
        this.dataSource.paginator = this.paginator;
      }
    }
  }

  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;

  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() add: EventEmitter<void> = new EventEmitter();
  @Output() select: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor() {}

  ngOnInit() {
    this.displayedColumns = Object.keys(this.tableColumns);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSelect(row) {
    if (this.selectable) {
      this.select.emit(row);
    }
  }
}
