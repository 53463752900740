import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material';

import * as fromComponents from './components';
import * as fromContainers from './containers';
import * as fromService from './services';
import * as fromGuards from './guards';

@NgModule({
  declarations: [...fromComponents.components, ...fromContainers.containers],
  imports: [SharedModule],
  providers: [
    {
      ...fromService.UserService,
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard' }
    },
    ...fromService.services,
    ...fromGuards.guards
  ],
  exports: [HttpClientModule]
})
export class CoreModule {}
