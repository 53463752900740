import { AddButtonComponent } from './add-button/add-button.component';
import { DataTableComponent } from './data-table/data-table.component';
import { HttpTableComponent } from './http-table/http-table.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

export const components = [
  AddButtonComponent,
  DataTableComponent,
  HttpTableComponent,
  TableHeaderComponent,
  ConfirmDialogComponent
];

export const entryComponents = [ConfirmDialogComponent];

export * from './add-button/add-button.component';
export * from './data-table/data-table.component';
export * from './http-table/http-table.component';
export * from './table-header/table-header.component';
export * from './confirm-dialog/confirm-dialog.component';
