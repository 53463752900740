import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-app-bar',
  template: `
    <mat-toolbar class="toolbar--border toolbar--color">
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="toolbar__left-item"
        >
          <div fxLayout="row" fxLayoutAlign="start center">
            <img class="toolbar__logo" [src]="logo" alt="Aidorando Logo" />
            <span class="mat-h1 toolbar__title toolbar__title--color ">{{
              title
            }}</span>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <button mat-icon-button>
            <mat-icon svgIcon="flag"></mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon svgIcon="sms"></mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon svgIcon="event_note"></mat-icon>
          </button>
          <mat-divider [vertical]="true"></mat-divider>
          <button
            mat-icon-button
            [matTooltip]="'change-language' | translate"
            [matMenuTriggerFor]="languageMenu"
            class="toolbar__flag"
          >
            <mat-icon [svgIcon]="flag"></mat-icon>
          </button>
          <mat-menu #languageMenu="matMenu">
            <button mat-menu-item (click)="onChangeLanguage('en')">
              <mat-icon svgIcon="english_flag"></mat-icon>
              English
            </button>
            <button mat-menu-item (click)="onChangeLanguage('de')">
              <mat-icon svgIcon="german_flag"></mat-icon>
              German
            </button>
          </mat-menu>
          <button mat-icon-button [matMenuTriggerFor]="accountMenu">
            <mat-icon svgIcon="person"></mat-icon>
          </button>

          <mat-menu #accountMenu="matMenu">
            <button mat-menu-item (click)="onLogOut()">
              <mat-icon matPrefix svgIcon="exit_to_app"></mat-icon>
              {{ 'logout' | translate | titlecase }}
            </button>
          </mat-menu>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  `,
  styleUrls: ['./app-bar.component.scss']
})
export class AppBarComponent implements OnInit {
  @Input() logo: string;
  @Input() title: string;
  @Output() logout = new EventEmitter();

  constructor(
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  onLogOut() {
    this.logout.emit();
  }
  onChangeLanguage(language: string) {
    localStorage.setItem(environment.localStorage.language, language);
    this.translate.use(language);
    this.dateAdapter.setLocale(language);

    location.reload();
  }
  get flag() {
    const language = localStorage.getItem(environment.localStorage.language);

    if (language === 'de') {
      return 'german_flag';
    }
    if (language === 'en') {
      return 'english_flag';
    }
  }
}
