import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { User } from '../../types/classes/user.class';
import { SidenavOption } from '../../types/interfaces/sidenav-option.interface';
import { SidenavService, UserService } from '../../services';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  template: `
    <app-app-bar
      [title]="'Aidorando'"
      [logo]="'../../../../assets/logo/aidorando-umbrela-logo.png'"
      (logout)="onLogOut()"
    ></app-app-bar>
    <div class="main-layout--color full-width full-height" fxLayout="row">
      <app-sidenav
        [sidenavOptions]="sidenavOptions"
        [active]="active"
        [user]="user$ | async"
      ></app-sidenav>

      <div
        fxLayout="column"
        class="main-layout__container full-width full-height flex"
      >
        <section class="main-layout__content full-height flex padding-32">
          <div class="flex full-width full-height">
            <div class="flex-1">
              <router-outlet></router-outlet>
            </div>
          </div>
        </section>
      </div>
    </div>
  `,
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  currentRoute$: BehaviorSubject<ActivatedRouteSnapshot>;
  user$: BehaviorSubject<User>;
  active: string;
  sidenavOptions: SidenavOption[];
  tabBarTitle: string;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private sidenavService: SidenavService,
    private router: Router
  ) {}

  ngOnInit() {
    this.user$ = this.userService.users$;
    this.sidenavOptions = this.sidenavService.buildSidenavOptions();

    this.setAppBarTitle();

    this.currentRoute$ = new BehaviorSubject(
      this.router.routerState.root.snapshot
    );
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setAppBarTitle();
        this.currentRoute$.next(this.router.routerState.root.snapshot);
      }
    });
  }

  onLogOut() {
    this.authService.logOut();
    this.router.navigate(['auth']);
  }

  private setAppBarTitle() {
    const url = this.router.url;
    this.active = url.split('/')[1] || '/';
    const option = this.sidenavOptions.find(
      value => value.routerLink === this.active
    );
    this.tabBarTitle = option ? option.name : 'Dashboard';
  }
}
